body {
    padding: 0px;
    margin: 0px;
    background-color: #090611;
}

html,
body {
    margin: 0;
    height: 100%;
    overflow: hidden;
}

.editor {
    counter-reset: line;
    /* border: 1px solid #ced4da; */
}

.editor #codeArea {
    outline: none;
    padding-left: 60px !important;
}

.editor pre {
    padding-left: 60px !important;
}

.editor .editorLineNumber {
    position: absolute;
    left: 0px;
    color: #cccccc;
    text-align: right;
    width: 40px;
    font-weight: 100;
}
